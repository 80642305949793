<template>
  <div class="flex items-center gap-2" @click.stop="onChange">
    <Checkbox
      v-if="!hideCheckbox"
      v-bind="checkbox"
      :checked="checked"
      :indeterminate="indeterminate"
      @click.stop="onChange"
    />
    <Avatar
      v-if="avatar"
      :src="avatar.photo"
      :name="avatar.fullName"
      v-bind="avatar"
      size="xs"
      :ui-size="{
        xs: 'w-6 h-6',
      }"
    />
    <Tooltip :text="text" arrow-class="!top-[unset]">
      <template #default="{ getTextRef }">
        <span
          :ref="getTextRef"
          :data-test="dataTest"
          :style="style"
          :class="['text-sm line-clamp-1 break-all', className]"
        >
          {{ text }}
        </span>
      </template>
    </Tooltip>
  </div>

  <Icon v-if="checked" name="heroicons:check" :size="20" class="min-w-5" />
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { StyleValue } from '@vue/runtime-dom/dist/runtime-dom.js'

defineProps({
  checkbox: {
    type: Object as PropType<{
      checked: boolean
      dataTest: string
    }>,
    required: false,
  },
  indeterminate: {
    type: Boolean,
    required: false,
  },
  avatar: {
    type: Object as PropType<{
      id: string
      fullName: string
      photo: string
    }>,
    required: false,
  },
  text: {
    type: String,
    required: true,
  },
  checked: {
    type: Boolean,
    required: true,
  },
  style: {
    type: Object as PropType<StyleValue>,
    required: false,
  },
  className: {
    type: String,
    required: false,
  },
  hideCheckbox: {
    type: Boolean,
  },
  dataTest: {
    type: String,
    required: false,
  },
})

const emit = defineEmits(['change'])

const onChange = (event: Event) => {
  emit('change', event)
}
</script>
